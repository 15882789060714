import React from "react";
import './CursoNefesh.css';
import {  Card, CardGroup } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";



function CursoNefesh(){
    const MySwal = withReactContent(Swal);

    const handleMatriculaClick = () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: "Já fez o curso Base?",
            text: "É necessário ter concluído o curso Base antes de iniciar o Nefesh.",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            reverseButtons: true,
            customClass: {
                popup: "custom-popup",
                title: "custom-title",
                htmlContainer: "custom-content",
                confirmButton: "custom-confirm-button",
                cancelButton: "custom-cancel-button",
                actions: "custom-actions" /* Classe para o contêiner dos botões */

            }
        }).then((result) => {
            if (result.isConfirmed) {
                // Redirecionar para o WhatsApp
                window.open("https://wa.me/559191545410", "_blank");
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire({
                    title: "Sem problemas!",
                    text: "Você ainda pode se preparar para o Nefesh.",
                    icon: "success",
                    customClass: {
                        popup: "custom-popup-cancel",
                        title: "custom-title-cancel",
                        htmlContainer: "custom-content-cancel",
                        confirmButton: "custom-confirm-button-cancel"
                    }
                });
            }
        });
    };
    return(
        <>
            <NavBar />
              <div className="curso-content">
                <div className="curso-header09">
                    <h1 className="curso-title09">Curso Nefesh</h1>
                    <button 
                        className="btn btn-primary btn-matricula" 
                        onClick={handleMatriculaClick}>
                        Matricule-se
                    </button>

                </div>
                <p className="curso-description09">
                NEFESH em hebraico significa ALMA, e essa palavra aparece mais de 700 vezes no Antigo Testamento, sendo usada muitas vezes no lugar da palavra pessoa. Algo como “Existem 33 nefesh na minha família”, ou seja, “seres viventes ou vidas”. Isso nos indica que a nossa alma é centro de VIDA e por isso devemos dar atenção e cuidado. Nossa NEFESH deve ser viva e ter sede de Deus assim como nosso Espírito.
                </p>
                <p className="curso-description09">
                A escola NEFESH traz luz e verdade sobre áreas ocultas das nossas vidas, traumas, abandonos, rejeições, abusos e outras coisas que gastamos muita energia e sofrimento tentando esconder, mas que Jesus quer curar, ressignificar as suas lembranças ruins.
                </p>
                <p className="curso-description09">
                ATENÇÃO: Para cursar o Nefesh você deve cursar o Base antes.O curso base trabalha desde o primeiro até o terceiro módulo temas que irão impulsionar sua vida, mudar completamente sua perspectiva cristã e te dar uma base de conhecimento de crescimento e maturidade espiritual.  
                </p>
                <p className="curso-description09">
                Aqui você irá aprender ouvir a voz de Deus e desenvolver um relacionamento íntimo e profundo com o Espírito Santo. A base que você precisa para levar uma vida cristã saudável está aqui. Venha conhecer mais do seu criador!
                </p>
            </div>
            <div className="footer-line-nefesh">
                <div className="content-nefesh1">
                    <h1 className="titulo-nefesh1">Metodologia</h1>
                    <p className="nefesh-description1">
                    Com três meses de duração e dois encontros semanais, a NEFESH inclui atendimentos individuais aos alunos e também grupos de compartilhamento chamados grupos grey, onde também recebemos cura ao ouvirmos os colegas e ao falarmos sobre o toque de Deus a cada aula.
                    </p>
                    <p className="nefesh-description1">
                    O maior objetivo dessa escola é ressignificar e buscar cura para as dores e traumas pelas quais você passou, levando você à sua forma original, como foi criado por Deus, com uma alma leve, livre e plena. Portanto, esteja com o coração humilde e ensinável, lembrando sempre que tudo que a gente viu e recebeu de Deus até hoje ainda não é tudo o que Ele tem para você.                    
                    </p>
                    <p className="nefesh-rodape1">
                    Lourenço e Márcia Ledo - Pastores Conselheiros da Link Church 
                    <br></br>
                    Coordenadores do Curso Nefesh - Aconselhamento, Cura e Libertação
                    </p> 
                </div>
            </div>
            <div className="footer-line-curso">
                <div className="content-modulo">
                <h1 className="titulo-curso09">Módulos:</h1>
                <CardGroup className="modulos-group2">
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">1</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>A visão de Amós</li>
                                    <li>Muro da rejeição</li>
                                    <li>Muro da rebelião</li>
                                    <li>Engano e falsos profetas</li>
                                    <li>Os trens da vida</li>
                                    <li>Muro da salvação</li>
                                    <li>O ciclo da liberdade</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">2</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>Introdução a libertação</li>
                                    <li>Consciência espiritua</li>
                                    <li>Sociedade Zumbi</li>
                                    <li>Perfil espiritual de Absalão</li>
                                    <li>Perfil espiritual de Jezabel</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">3</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>A comunicação e os princípios bíblicos</li>
                                    <li>Administrando conflitos</li>
                                    <li>A raiva nos relacionamentos</li>
                                    <li>Temperamentos</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">4</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>As emoções fundamentais</li>
                                    <li>Lidando com o medo</li>
                                    <li>O processo Emanuel</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">5</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>Abusos</li>
                                    <li>Vícios e suas raízes</li>
                                    <li>Família funcional e disfuncional</li>
                                    <li>As consequeências da disfuncionalidade </li>
                                    <li>Ferramentas de aconselhamento</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>
                </div>

            </div>
            <div className="footer-line3">
                <div className="contact-info2">
                    <span className="ctt2">CONTATO</span>
                    <div className="contact-details">
                        <div className="contact-item">  
                        <a href="https://wa.me/559191545410" target="_blank" rel="noopener noreferrer"> 
                            <i className="bi bi-whatsapp contact-icon"></i>
                            <span className="ctt-ctt">9191545410</span>
                        </a>
                        </div>
                        <div className="contact-item">
                        <a href="https://www.instagram.com/linkschoolbr/" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-instagram contact-icon"></i>
                            <span className="ctt-ctt">linkschoolbr</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CursoNefesh;