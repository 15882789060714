import React from "react";
import './CursoBase.css';
import { Dropdown, Card, CardGroup } from 'react-bootstrap';
import NavBar from '../components/NavBar';

function CursoBase(){
    return(
        <>
            <NavBar />

             <div className="curso-content1">
                <div className="curso-header1">
                    <h1 className="curso-title1">Curso Base</h1>
                    <a class="btn btn-primary btn-matricula" href="https://tiketo.com.br/evento/2262" role="button" target="_blank" rel="noopener noreferrer">Matricule-se</a>

                </div>
                <p className="curso-description1">
                    O curso base trabalha desde o primeiro até o terceiro módulo temas que irão impulsionar sua vida,
                    mudar completamente sua perspectiva cristã e te dar uma base de conhecimento de crescimento e
                    maturidade espiritual.
                </p>
                <p className="curso-description1">
                    Aqui você irá aprender ouvir a voz de Deus e desenvolver um relacionamento íntimo e profundo com o
                    Espírito Santo. A base que você precisa para levar uma vida cristã saudável está aqui. Venha conhecer
                    mais do seu criador!
                </p>
            </div>
            <div className="footer-line-curso">
                <div className="content-modulo">
                <h1 className="titulo-curso1">Módulos:</h1>
                <CardGroup className="modulos-group">
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">1</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>Relacionamento com Deus</li>
                                    <li>O Pecado</li>
                                    <li>Quebrantamento e Arrependimento</li>
                                    <li>Obediência e Autoridade</li>
                                    <li>Santidade</li>
                                    <li>Orgulho X Humildade</li>
                                    <li>Vivendo na Verdade</li>
                                    <li>O Pode da Cruz</li>
                                    <li>Sobrenatural de Deus</li>
                                    <li>O Coração Paterno de Deus</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">2</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>A Raiz da Dor</li>
                                    <li>Perfis Espirituais (parte 1)</li>
                                    <li>Perfis Espirituais (parte 2)</li>
                                    <li>Economia do Reino</li>
                                    <li>Atributos de Deus</li>
                                    <li>Auto Imagem e Valor Do Homem</li>
                                    <li>Família e Base da Sociedade</li>
                                    <li>Vencendo a Luxúria</li>
                                    <li>Ciclo da Liberdade</li>
                                    <li>Estações de Bênçãos (aula prática)</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">3</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>Relacionamento com Deus</li>
                                    <li>O Pecado</li>
                                    <li>Quebrantamento e Arrependimento</li>
                                    <li>Cosmovisão Bíblica</li>
                                    <li>Esferas Sociais</li>
                                    <li>Chamado e Propósito de Vida</li>
                                    <li>Os 5 Ministérios</li>
                                    <li>Dons e Manifestações Divinas</li>
                                    <li>Renunciando Meus Direitos</li>
                                    <li>Serviço a Deus</li>
                                    <li>Lealdade</li>
                                    <li>Liderança e Discipulado</li>
                                    <li>O Reino de Deus</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>
                <div className="matricula-button-container">
                     <a class="btn btn-primary matricula-button1" href="https://tiketo.com.br/evento/2262" role="button" target="_blank" rel="noopener noreferrer">Matrículas aqui</a>
                </div>
                </div>
                <div className="footer-line3">
                <div className="contact-info2">
                    <span className="ctt2">CONTATO</span>
                    <div className="contact-details">
                        <div className="contact-item">  
                        <a href="https://wa.me/559191545410" target="_blank" rel="noopener noreferrer"> 
                            <i className="bi bi-whatsapp contact-icon"></i>
                            <span className="ctt-ctt">9191545410</span>
                        </a>
                        </div>
                        <div className="contact-item">
                        <a href="https://www.instagram.com/linkschoolbr/" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-instagram contact-icon"></i>
                            <span className="ctt-ctt">linkschoolbr</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    );

}
export default CursoBase;